import {Api} from './api';

export class InvoiceService {

	getIntroduction() {
		return Api.get(`/iam-system/product-introduction/view`).then(res => res.data);
	}

	getToken() {
		return Api.get(`/iam-auth/token`).then(res => res.data);
	}

	getHeaderInfo(referenceNumber) {
		return Api.get(`/iam-entry/invoices/${referenceNumber}/header-info`).then(res => res.data);
	}


	sendZaloMessage(toTelecomNumber,token) {
		return Api.put(`/iam-entry/test-invoice/send-zalo`, {toTelecomNumber: toTelecomNumber}, {headers :{"x-csrf-token": token}}).then(res => res.data);
	}

}
