import {useEffect, useState} from 'react';
import {Link, Route} from 'react-router-dom';

import {Dialog} from 'primereact/dialog';

import {InvoiceService} from '../service/InvoiceService';

import {TraCuu} from './tra-cuu';

const invoiceService = new InvoiceService();

export const App = () => {

	const [displayAds, setDisplayAds] = useState('');

	useEffect(() => {
		invoiceService.getIntroduction().then(data => {
			if (data.description) {
				setDisplayAds(data.description);
			}
		}).catch(() => {
		});
	}, []);

	return (
		<div className="App">

			<div>
				<Route path="/" exact component={TraCuu}/>
			</div>

			<Dialog visible={!!displayAds} style={{width: '40rem'}} onHide={() => setDisplayAds('')}>
				<div className="grid">
					<div className="col-12">
						<div dangerouslySetInnerHTML={{__html: displayAds}}></div>
					</div>
				</div>
			</Dialog>
		</div>
	);
}
